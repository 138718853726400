import React from 'react'
import * as styles from './TourDate.module.scss'

import classnames from 'classnames'

import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const DateContent = ({ blok, isThree }: any) => {
  const { title, info_text, sold_out, link } = blok
  const hasLink = link && link.cached_url
  return (
    <React.Fragment>
      <div className={classnames('date', styles.title)}>{title}</div>
      {info_text && (
        <span
          className={classnames(
            styles.info,
            isThree ? 'body-text' : 'label',
            isThree ? styles.isThree : ''
          )}
        >
          {info_text}
        </span>
      )}
      {hasLink && (
        <div className={styles.box}>
          <svg
            width="40"
            height="30"
            viewBox="0 0 40 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.8251 0.100098L39.0751 14.3501V15.6501L24.8251 29.9001L21.925 27.0001L32.0251 17.0001H0.925049V13.0501H32.0751L21.925 3.0001L24.8251 0.100098Z"
              fill="#D9D9D9"
            />
          </svg>
        </div>
      )}
    </React.Fragment>
  )
}

const TourDate = ({ blok, isThree }: any) => {
  const { title, info_text, sold_out, link } = blok
  const hasLink = link && link.cached_url
  return (
    <SbEditable content={blok}>
      {hasLink ? (
        <LinkComponent
          to={link.cached_url}
          className={classnames(
            styles.button,
            hasLink ? styles.hasLink : '',
            sold_out ? styles.soldOut : '',
            info_text ? styles.hasInfo : ''
          )}
        >
          <DateContent blok={blok} isThree />
        </LinkComponent>
      ) : (
        <div
          className={classnames(
            styles.button,
            hasLink ? styles.hasLink : '',
            sold_out ? styles.soldOut : '',
            info_text ? styles.hasInfo : ''
          )}
        >
          <DateContent blok={blok} isThree />
        </div>
      )}
    </SbEditable>
  )
}

export default TourDate
